<i18n locale="th" lang="yaml" >
page.title : "Privacy Policy"
page.back : "กลับสู่หน้าเข้าสู่ระบบ"
</i18n>

<template>
	<div id="privacy_pulicy_login_page">
		<PrivacyPolicy/>
		<my-router-link  id="privacy_policy_back_button_link" class="back" name="login" no-param>
			{{$t('page.back')}}
    </my-router-link>
	</div>

</template>

<script>
import PrivacyPolicy from "@/src/components/common/PrivacyPolicyContent.vue"
export default {
	components : {
		PrivacyPolicy
	} ,
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	data() {
		return {

		}
	} ,
	computed : {

	} ,
	methods : {

	}
}
</script>


<style lang="less" scoped>

.back {
	margin-top: 4vw;
	font-size: 1.5em;
}

</style>
